import {Empty, Table as AntTable, TableProps} from 'antd';
import React from "react";


class Table extends React.Component<TableProps<any>> {
    render() {
        return (
            <AntTable
                {...this.props}
                locale={{
                    emptyText: <Empty className={'m-2 font-size-sm'} description={'Žádné výsledky'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }}
            />
        )
    }
}

export default Table