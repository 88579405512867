import React from "react";
import {Avatar, Col, List, Result, Row, Spin, Tooltip, Typography} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {CheckCircleOutlined, EyeInvisibleOutlined, EyeOutlined, MailOutlined, WarningOutlined} from "@ant-design/icons";
import moment from "moment";
import INotification, {STATES} from "model/interface/ui/INotification"
import {motion} from "framer-motion";
import selectors from "../../../../redux/selectors";
import UserAvatar from "../../security/UserAvatar";
import NotificationActions from "./NotificationActions";
import {IAppState} from "../../../../redux/store";
import Button from "../../../shared/button/Button";

interface IState {
    detail: number[]
}

interface IProps {
    findUnread: () => INotification[],
    findNotDeleted: (limit?: number) => INotification[],
    limit?: number
    showRead?: boolean
    showAvatar?: boolean
    loading: boolean
}


export const getNotificationIcon = (icon: string) => {
    switch (icon) {
        case 'mail':
            return <MailOutlined/>;
        case 'alert':
            return <WarningOutlined/>;
        case 'check':
            return <CheckCircleOutlined/>
        default:
            return <MailOutlined/>;
    }
}

class NotificationList extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            detail: []
        }
    }

    showDetail(id: number) {
        this.setState(state => ({
            detail: state.detail.includes(id) ? state.detail.filter(d => d !== id) : [...state.detail, id]
        }))
    }

    renderNotification(notification: INotification) {
        const {showAvatar} = this.props
        const isDetail = this.state.detail.includes(notification.id);
        return <>
            <div className={"d-flex flex-row  w-100  justify-content-between"}>
                <Row justify={'start'} className={'w-100'} style={{flex: 1, minWidth: 0}} align={"middle"}>
                    <div className="pr-3">
                        {notification.sender && (showAvatar || showAvatar === undefined) ? (
                            <UserAvatar className={'m-0'} size={36} user={notification.sender}/>
                        ) : <Avatar size={36} className={`ant-avatar-alert`} icon={getNotificationIcon("alert")}/>}
                    </div>
                    <div style={{flex: 1, minWidth: 0}}
                         className={"d-flex flex-row  mr-3 w-100  justify-content-between align-items-center"}>
                        <Typography.Paragraph ellipsis={isDetail ? false : {rows: 3}}
                                              className={"text-gray-light mb-0 " + (isDetail ? 'overflow-hidden' : '')}>{notification.text}
                            <motion.div className={'overflow-hidden mt-2 text-dark'}
                                        initial={{
                                            height: isDetail ? "auto" : "0px",
                                            display: isDetail ? "block" : "none"
                                        }}
                                        transition={{duration: 0.5}}
                                        animate={{
                                            height: isDetail ? "auto" : "0px",
                                            transitionEnd: {
                                                display: isDetail ? "block" : "none",
                                            }
                                        }}
                            >
                                {notification.message.body}
                            </motion.div>
                        </Typography.Paragraph>
                    </div>
                </Row>
                <div className={"text-right"} onClick={e => {
                    e.stopPropagation();
                    e.preventDefault()
                }}>
                    <Row gutter={[4, 4]} className={'ml-1'}>
                        {notification.message.body && (
                            <Col>
                                <Tooltip title={!isDetail ? "Zobrazit detail upozornění" : "Skrýt detail upozornění"}>
                                    <Button shape={"circle"} type={isDetail ? "default" : "info"} size={"extraSmall"}
                                            className={"ml-1"} onClick={() => this.showDetail(notification.id)}
                                            icon={!isDetail ? <EyeOutlined/> : <EyeInvisibleOutlined/>}>
                                    </Button>
                                </Tooltip>
                            </Col>
                        )}
                        <NotificationActions size={"extraSmall"} notification={{...notification}}/>
                    </Row>
                    <div className="font-size-xs pr-1">{moment(notification.scheduledAt.raw).fromNow()}</div>
                </div>
            </div>
        </>
    }

    render() {
        const {limit, showRead, loading} = this.props
        const notifications = this.props.findNotDeleted(limit || 50)
            .filter(n => showRead === undefined ? true : showRead || n.state !== STATES.STATE_VISITED) // todo not limit, or implement pagination/scroll on load

        return notifications.length > 0 ? (
            <List
                loading={loading}
                size="small"
                itemLayout="horizontal"
                dataSource={notifications.sort((a, b) => moment(a.scheduledAt.raw).isAfter(moment(b.scheduledAt.raw)) ? -1 : 1)} //DESC or should not be ? :D
                renderItem={notification => {
                    return (
                        <List.Item
                            className={"w-100" + (notification.state === STATES.STATE_VISITED ? ' bg-gray-lightest opacity-0-6' : '')}>
                            <Spin spinning={notification.state === STATES.STATE_LOADING} className={'w-100'}
                                  wrapperClassName={'w-100'}>
                                {notification.message.url ? (
                                    <Link to={notification.message.url}>
                                        {this.renderNotification(notification)}
                                    </Link>
                                ) : (
                                    <div>
                                        {this.renderNotification(notification)}
                                    </div>
                                )}
                            </Spin>
                        </List.Item>
                    )
                }}
            />
        ) : (
            <div className="empty-notification">
                <Result
                    status="success"
                    className={"opacity-0-5"}
                    title="Žádné notifikace"
                />
            </div>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    const {loading} = state.notifications
    return {
        loading,
        findUnread: (): INotification[] => selectors.notifications.findUnread(state),
        findNotDeleted: (limit?: number): INotification[] => selectors.notifications.findNotDeleted(state, limit)
    }
}

export default connect(mapStateToProps)(NotificationList);
