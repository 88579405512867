import fetch from 'model/auth/ETagCachedFetchInterceptor'
import Utils from "../../../utils";
import IRestResource from "../../interface/api/IRestResource";
import IRestCollectionService from "../../interface/api/IRestCollectionService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";

const RestService: IRestCollectionService = {
    collectionList: function (collection: string, options?){
        const disableCache = options && options.cache === false
        // @ts-ignore // TODO
        return fetch({
            cacheKey: disableCache ? null : collection,
            url: '/' + collection,
            method: 'get',
            params: options
        }) as Promise<any>
    },

    collectionListInner: function (collection: string, id:string|number, parts = [], options?): Promise<any> {
        // @ts-ignore // TODO
        return fetch({
            cacheKey: collection,
            url: '/' + collection + '/' + id + '/' + parts.join('/') ,
            method: 'get',
            params: options
        })
    },

    collectionCreate: function (collection, data, options? ) {
        const fileUpload = options ? options.fileUpload : false
        // @ts-ignore
        return fetch({
            url: '/' + collection,
            method: 'post',
            params: options,
            // data: data, // not working for data storage script upload
            data: fileUpload ? Utils.convertArrayToFormData(data) : data, // use fileUpload if service is to upload files
            headers: fileUpload ? {"Content-Transfer-Encoding": "multipart/form-data"} : {}
        }).then(data => {
            localStorage.removeItem(collection) // TODO
            try {
                // @ts-ignore // TODO
                localStorage.setItem(collection + '/' + data.resource.id, JSON.stringify(data))
            } catch(e) {
                Utils.cleanStorage()
                // @ts-ignore // TODO
                // localStorage.setItem(collection + '/' + data.resource.id, JSON.stringify(data))
            }
            // @ts-ignore // TODO
            return data.resource
        })
    },
    resourceRetrieve: function (collection, id, options?: IRestServiceOptions) {
        const disableCache = options && options.cache === false
        // @ts-ignore // TODO
        return fetch({
            cacheKey: disableCache ? null : collection + '/' + id,
            url: '/' + collection + '/' + id,
            method: 'get',
            params: options
        }).then(response => {
            // @ts-ignore // TODO
            return response.resource;
        })
    },
    resourceDelete: function (collection, id) {
        // @ts-ignore // TODO
        return fetch({
            cacheKey: null,
            url: '/' + collection + '/' + id,
            method: 'delete'
        }).then(() => {
            localStorage.removeItem(collection)
            localStorage.removeItem(collection + '/' + id)
        })
    },
    resourceUpdate: function (collection, id, data, options) {
        const fileUpload = options && !!options.fileUpload
        // @ts-ignore // TODO
        return fetch({
            cacheKey: null,
            url: '/' + collection + '/' + id + '?_method=put',
            method: 'post',
            params: options,
            headers: fileUpload ? {"Content-Transfer-Encoding": "multipart/form-data"} : {},
            data: fileUpload ? Utils.convertArrayToFormData(data) : Utils.setAllUndefinedPropertiesToNull(data),
            // data: data, //Utils.convertArrayToFormData(data), // not working for data storage script upload
        }).then(data => {
            localStorage.removeItem(collection) // TODO
            // @ts-ignore // TODO
            try {
                // @ts-ignore // TODO
                localStorage.setItem(collection + '/' + data.resource.id, JSON.stringify(data))
            } catch(e) {
                Utils.cleanStorage()
                // @ts-ignore // TODO
                // localStorage.setItem(collection + '/' + data.resource.id, JSON.stringify(data))
            }
            // @ts-ignore // TODO
            return data.resource
        })
    },
    resourcesUpdate: function (collection, ids, data, options) {
        const fileUpload = options && !!options.fileUpload
        const allData = {ids: ids, ...data}
        // @ts-ignore // TODO
        return fetch({
            cacheKey: null,
            url: '/' + collection + '?_method=put',
            method: 'post',
            params: options,
            headers: fileUpload ? {"Content-Transfer-Encoding": "multipart/form-data"} : {},
            data: fileUpload ? Utils.convertArrayToFormData(allData) : allData,
            // data: data, //Utils.convertArrayToFormData(data), // not working for data storage script upload
        }).then(() => {
            localStorage.removeItem(collection) // TODO
        })
    },
    resourceDownload: (collection, download: boolean = true, fileName) => {
        return fetch({
            responseType: "blob",
            url: collection,
            method: 'get',
        }).then((response: { data: Blob, fileName: string } | any) => {
            download && Utils.downloadFile(response.data, fileName || response.fileName)
            return {blob: response.data, fileName: fileName || response.fileName}
        })
    },
    customRetrieve: (collection, options?: IRestServiceOptions) => {
        const disableCache = options && options.cache === false
        // @ts-ignore // TODO
        return fetch({
            cacheKey: disableCache ? null : collection,
            url: '/' + collection,
            method: 'get',
            params: options
        }) as any
    },
}

export default RestService