import {Form} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import IBaseProps from "../../../../../../model/interface/IBaseProps";
import IRestServiceFilters from "../../../../../../model/interface/api/IRestServiceFilters";
import FilterTreeBuilder from "../../../../view/settings/customFilters/FilterTreeBuilder";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";

export interface IFieldContentTypeOptions {
    contentTypeFilters?: IRestServiceFilters
}

interface IProps extends IFieldOptionsEditorProps, IBaseProps {
    targetContentType: IContentType
}

class FieldContentTypeOptionsEditor extends React.Component<IProps> {

    render() {
        const {history, match, targetContentType, buildFieldName} = this.props
        return (
            <div>
                <Form.Item name={buildFieldName('contentTypeFilters')} label={'Filter'}>
                    <FilterTreeBuilder history={history} match={match}
                                       contentTypeFullClassName={targetContentType.fullClassName}/>
                </Form.Item>
            </div>
        )
    }
}


export default FieldContentTypeOptionsEditor