import React, {RefObject} from "react";
import {CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {FormInstance, Modal, Popconfirm, Row, Table, Tooltip} from "antd";
import Utils from "../../../../utils";
import {connect, RootStateOrAny} from "react-redux";
import ViewsService from "../../../../model/service/dataStorage/ViewsService";
import IView from "../../../../model/interface/dataStorage/IView";
import ViewForm from "../view/ViewForm";
import {update} from "../../../../redux/actions/Setup";
import {ISetupState} from "../../../../redux/reducers/Setup";
import Hotkey from "../../../shared/hotkey/Hotkey";
import Button from "../../../shared/button/Button";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";

interface IProps extends IContentTypeStepProps {
    views: IView[]
    update: (changes: any) => void
}

interface IState {
    view: null | IView,
    formRef: RefObject<FormInstance>,
    deleting: string[]
}

class ContentTypeViews extends React.Component<IProps, IState> {


    constructor(props: IProps) {
        super(props)
        this.state = {
            view: null,
            formRef: React.createRef() as RefObject<FormInstance>,
            deleting: []
        }
    }

    edit = (view: IView) => {
        this.setState({view})
    }

    create() {
        const {resource} = this.props
        this.setState({
            view: {
                uuid: Utils.uuid(),
                name: '',
                description: '',
                contentType: resource.uuid,
                defaultUnit: '',
                label: '',
                contentTypes: [],
                id: 0,
                units: [],
                allowSettings: true,
                allowExport: true,
            }
        }, this.state.formRef.current?.resetFields)
    }

    cancel() {
        this.setState({view: null})
    }

    delete(view: IView) {
        const {views, update, onValuesChange, resource} = this.props
        this.setState(state => ({deleting: [...state.deleting, view.uuid]}))
        ViewsService.resourceDelete(view.id).then(() => {
            this.setState(state => ({deleting: [...state.deleting.filter(v => v !== view.uuid)]}))
            onValuesChange({views: resource.views.filter(v => v !== view.uuid)})
            update({
                views: [...views.filter(v => v.uuid !== view.uuid)]
            })
        })
    }

    onClone(view: IView) {
        Modal.confirm({
            title: 'Opravdu chcete vytvořit kopii tohoto pohledu?',
            onOk: () => {
                this.edit(ViewsService.clone(view))
            }
        })
    }

    afterSave(view: IView) {
        const {onValuesChange, resource} = this.props
        return new Promise<void>(resolve => {
            onValuesChange({
                views: resource.views.includes(view.uuid) ? resource.views : [...resource.views, view.uuid]
            })
            this.setState({view: null}, () => resolve())
        })
    }

    render() {
        const {view, deleting} = this.state
        const {views, resource} = this.props

        const viewColumns = [
            {
                title: 'Titulek',
                dataIndex: 'label'
            },
            {
                title: 'Název',
                dataIndex: 'name'
            },
            {
                title: <Row justify={"end"}>
                    <Hotkey help={"Přidat pohled"} keys={["Alt", "p"]} trigger={() => this.create()}>
                        <Button type={"success"} onClick={() => this.create()} icon={<PlusOutlined/>}>
                            <u className={'pl-2'}>P</u>řidat
                        </Button>
                    </Hotkey>
                </Row>,
                key: 'actions',
                dataIndex: 'actions',
                render: (_: any, elm: IView) => (
                    <div className="text-right d-flex justify-content-end">
                        <Tooltip title={"Upravit"}>
                            <Button onClick={() => this.edit(elm)} type="link" className="mr-2" icon={<EditOutlined/>}
                                    size="small"/>
                        </Tooltip>
                        <Tooltip title={"Vytvořit kopii"}>
                            <Button onClick={() => this.onClone(elm)} type="link" className="mr-2"
                                    icon={<CopyOutlined/>} size="small"/>
                        </Tooltip>
                        <Tooltip title={"Odstranit"}>
                            <Popconfirm title="Opravdu smazat pohled?" onConfirm={() => this.delete(elm)}
                                        okText="Ano" cancelText="Ne">
                                <Button loading={deleting.includes(elm.uuid)} type={'link'} danger={true}
                                        icon={<DeleteOutlined/>} size="small"/>
                            </Popconfirm>
                        </Tooltip>
                    </div>
                )
            }
        ]
        return (
            <>
                {view && <Modal title={"Nastavení pohledu"} visible={true} onCancel={() => this.cancel()}
                                okButtonProps={{hidden: true}} width={800}>
                    <ViewForm afterSave={this.afterSave} resource={view} history={this.props.history}
                              match={this.props.match} updateSetup={true}/>
                </Modal>}
                <Table
                    pagination={{pageSize: 10, showSizeChanger: true}}
                    columns={viewColumns}
                    dataSource={[...views.filter(view => {
                        return view.contentType === resource!.uuid
                    })
                        .sort((a, b) => {
                            return a.label > b.label ? 1 : -1
                        })]}
                    rowKey={row => row.uuid}
                />
            </>
        )
    }
}

const mapStateToProps = ({setup}: RootStateOrAny) => {
    const {scripts} = setup as ISetupState
    return {
        scripts
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        update: (changes: any) => dispatch(update(changes))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentTypeViews)