import enLang from './entries/en_US';
import csLang from './entries/cs_CZ';
// import zhLang from './entries/zh_CN';
// import frLang from './entries/fr_FR';
// import jaLang from './entries/ja_JP'

const AppLocale = {
    cs: csLang,
    en: enLang,
    // zh: zhLang,
    // fr: frLang,
    // ja: jaLang
};

export default AppLocale;